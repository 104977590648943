import React, { Component } from "react";
import "./Footer.css";
import { lang } from "../../service";

class Footer extends Component {
  email() {
    let m = "";
    let ryzerdp = ["i", "@", "o", "i", "l", "b", "l", "t", "w", "r", "a", "i", "@", ".", "h", "<", "a", ">", "r", "b", "n", "u", "r", "z", "m", "t", "s", ".", "e", "=", " ", "\"", "m", "o", "c", " ", "r", "m", "a", "e", "y", "y", "a", "\"", "e", "m", "i", "s", "f", "a", "f", "/", "s", "t", "k", "p", "r", ":", "y", "n", "a", "a", "z", "r", "i", "e", "p", "i", "<", "w", "\"", "\"", "z", "f", "s", "l", "z", "s", "o", "k", "c", "y", "o", "r", "o", "=", "s", "c", ">", "u"];
    let yjefkah = [81, 63, 41, 38, 12, 73, 46, 13, 78, 68, 47, 11, 20, 39, 3, 0, 21, 89, 70, 30, 17, 24, 27, 71, 53, 23, 48, 82, 34, 7, 2, 57, 42, 84, 40, 44, 65, 9, 1, 5, 29, 74, 64, 43, 77, 85, 16, 75, 61, 10, 18, 87, 49, 66, 37, 26, 25, 15, 72, 60, 54, 88, 76, 22, 55, 52, 69, 59, 86, 35, 51, 8, 28, 6, 79, 56, 33, 36, 62, 80, 45, 31, 14, 4, 19, 50, 32, 83, 58, 67];
    let fenhpdv = new Array();
    for (var i = 0; i < yjefkah.length; i++) {
      fenhpdv[yjefkah[i]] = ryzerdp[i];
    }
    for (var i = 0; i < fenhpdv.length; i++) {
      m += fenhpdv[i];
    }
    return m;
  }

  render() {
    const PoweredBy = props => {
      const { show } = props;
      if (show) {
        return (
          <section className="poweredby">
            Proudly published with <a href="https://gatsbyjs.org">Gatsby</a>
          </section>
        );
      }
      return null;
    };

    const { promoteGatsby } = this.props;
    const { label, url, year } = this.props.copyright;
    return (
      <footer className="site-footer clearfix" name="contact">
        <div className="footer-contact">{lang ? "Contact" : "Kontakt"}</div>

            <div dangerouslySetInnerHTML={{__html: this.email()}} />

        <section className="copyright">
          <a href={url || "/"}>{label}</a> &copy;{" "}
          {year || new Date().getFullYear()}
        </section>
        <PoweredBy show={promoteGatsby}/>
      </footer>
    );
  }
}

export default Footer;
