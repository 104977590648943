import React, { Component } from "react";
import "./Projects.css";
import Project from '../Project/Project';
import { lang } from "../../service";

class Projects extends Component {
  render() {
    return (
      <div className="projects">
        <h1>
          {lang ? "Projects" : "Projekty"}
        </h1>
        <div className="projects-circle"><div>{this.props.items.length}</div>
        </div>
        <div className="projects-items">

          {this.props.items.map(item => <Project key={item.node.id} item={item.node} />)}
        </div>
      </div>
    );
  }
}

export default Projects;
