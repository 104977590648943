import React, { Component } from "react";
import Helmet from "react-helmet";
import Projects from "../components/Projects/Projects";
import config from "../../data/SiteConfig";
import { graphql } from "gatsby";
import { orderBy } from "lodash";
import MainLayout from "../components/layout";
import SEO from "../components/SEO/SEO";
import MainHeader from "../components/MainHeader/MainHeader";
import PageTitle from "../components/PageTitle/PageTitle";
import PageDescription from "../components/PageDescription/PageDescription";
import SocialMediaIcons from "../components/SocialMediaIcons/SocialMediaIcons";
import { Link } from "react-scroll/modules/index";

class IndexPage extends Component {
  render() {
    return (
      <MainLayout location={this.props.location}>
        <SEO postEdges={this.props.nodes} />
        <MainHeader cover={config.siteCover}>
          <div className="vertical">
            <div className="main-header-content inner">
              <PageTitle text={config.siteTitle}/>
              <PageDescription text={config.siteDescription}/>
              <SocialMediaIcons
                urls={config.siteSocialUrls}
                color="currentColor"
              />
            </div>
          </div>
          <Link
            className="scroll-down icon-arrow-left"
            to="content"
            data-offset="-45"
            spy
            smooth
            duration={500}
          >
            <span className="hidden">Scroll Down</span>
          </Link>
        </MainHeader>
        <div className="projects-container" name="content">
          <Helmet title={`Projects | ${config.siteTitle}`}/>
          <Projects items={orderBy(this.props.data.allSitesJson.edges, function(o) {
            return new Number(o.node.id);
          }, ["desc"])}/>
        </div>
      </MainLayout>
    );
  }
}

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    allSitesJson {
      edges {
        node {
          id
          name
          description
          technology
          url
          active
          description_front_en
          name_front_en
          description_front
          name_front
          photo
          updated_at
          created_at
        }
      }
    }
  }
`;
