import React, { Component } from "react";
import "./Project.css";
import { lang } from '../../service';
class Project extends Component {
  render() {
    return (
      <div className="project">

        <h5>{this.props.item['name_front'+lang]}</h5>
        <div className="description">{this.props.item['description_front'+lang]}</div>
        <div className="technology">{this.props.item.technology}</div>
      </div>
    );
  }
}

export default Project;
