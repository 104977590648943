// import FontIcon from "react-md/lib/FontIcons";
import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll/modules/index";
import React from "react";
import MainHeader from "../MainHeader/MainHeader";
import { lang } from "../../service";

function GetNavList(config) {
  const NavList = [
    {
      primaryText: lang ? "Home" : "Strona domowa",
      // LeftIcon: <p>home</p>,
      component: Link,
      to: "/"
    },
    {
      divider: true
    }
  ];

  NavList.push(
    <li key="projectscroll" role="presentation" className="nav-opened">
      <ScrollLink to="content" data-offset="-45" spy smooth duration={500}>
        {lang ? "Projects" : "Projekty"}
      </ScrollLink>
    </li>
  );
  if (config.userLinks) {
    config.userLinks.forEach(link => {
      NavList.push({
        primaryText: link.label,
        // LeftIcon: <FontIcon forceSize iconClassName={link.iconClassName} />,
        component: "a",
        href: link.url
      });
    });
  }

  NavList.push({ divider: true });

  NavList.push(
    <li key="contactscroll" role="presentation" className="nav-opened">
      <ScrollLink to="contact" data-offset="-45" spy smooth duration={500}>
        {lang ? "Contact" : "Kontakt"}
      </ScrollLink>
    </li>
  );
  return NavList;
}
export default GetNavList;
